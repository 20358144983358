import logo from '../../logo.svg';
import './App.css';
import { Counter } from "../counter/Counter";
import { useCallsWebSocket, useCurrentCall, useLastCalls } from "../../hooks/calls";
import React from "react";

/**
 * @param {string} phone
 * @param {number} time en secondes
 * @param {Date} start_date
 * @param {Date|undefined} end_date
 * @param {boolean} inProgress
 * @param {Function} hangUp
 * @returns {JSX.Element}
 */
const Call = ({ phone, time = 0, start_date, end_date = undefined, inProgress = false, hangUp = () => null }) => {
    return (<div style={{
        border: '1px solid rgba(255, 255, 255, .5)',
        borderRadius: '5px',
        minWidth: '600px',
        background: (inProgress ? 'darkgreen' : 'darkred'),
        textAlign: 'left',
        padding: '10px',
        marginBottom: '5px' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>
                {start_date.getDate() < 10 && '0'}{start_date.getDate()}/
                {start_date.getMonth() < 10 && '0'}{start_date.getMonth()}/
                {start_date.getFullYear()} {start_date.getHours() < 10 && '0'}{start_date.getHours()}:
                {start_date.getMinutes() < 10 && '0'}{start_date.getMinutes()}:
                {start_date.getSeconds() < 10 && '0'}{start_date.getSeconds()}
            </span>

            {end_date && (<span>
                {end_date.getDate() < 10 && '0'}{end_date.getDate()}/
                {end_date.getMonth() < 10 && '0'}{end_date.getMonth()}/
                {end_date.getFullYear()} {end_date.getHours() < 10 && '0'}{end_date.getHours()}:
                {end_date.getMinutes() < 10 && '0'}{end_date.getMinutes()}:
                {end_date.getSeconds() < 10 && '0'}{end_date.getSeconds()}
            </span>)}
        </div>

        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div>
                <h3> {phone} </h3>

                <p> {time} s </p>
            </div>

            {inProgress && (<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                <button style={{ padding: '10px', background: 'transparent', fontSize: '20px', border: '1px solid black', transform: 'translateY(-45%)', cursor: 'pointer' }} onClick={hangUp}>
                    Raccrocher
                </button>
            </div>)}
        </div>
    </div>);
}

const WebSocketConnectionStatusLog = ({ s }) =>
    (<span>
        WebSocket connection :
        {s === 'Open' ?
            (<span style={{ color: 'green' }}> ✔ </span>) :
            (<span style={{ color: 'red' }}> ✖ </span>)}
    </span>);

function App() {
  const { connectionStatus, send_hang_up } = useCallsWebSocket();
  const currentCall = useCurrentCall();
  const calls = useLastCalls();

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />

        <main>
            <Counter />

            <div>
                <WebSocketConnectionStatusLog s={connectionStatus} />

                {currentCall.phone &&
                    (<Call phone={currentCall.phone}
                           time={currentCall.time}
                           start_date={currentCall.start_date}
                           end_date={currentCall.end_date}
                           inProgress={true} hangUp={send_hang_up} />)}
                <div>
                    {[...calls].reverse().map((call, i) =>
                        (<Call key={i} {...call} inProgress={false} />))}
                </div>
            </div>
        </main>
      </header>
    </div>
  );
}

export default App;
