import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { decrement, increment } from '../../store/slice';

export const Counter = () => {
	const count = useSelector(state => state.counter.value);
	const dispatch = useDispatch();

	return (<div>
		<div>
			<button
				aria-label={'Decrement value'}
				onClick={() => dispatch(decrement())}>
				-
			</button>

			<span>{count}</span>

			<button
				aria-label={'Increment value'}
				onClick={() => dispatch(increment())}>
				+
			</button>
		</div>
	</div>)
};
