import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	counter: {
		value: 0
	},

	calls: {
		calls: [],
		currentCall: {}
	}
};

const slice = createSlice({
	name: 'state',
	initialState,
	reducers: {
		// Counter
		increment(state) {
			state.counter.value += 1;
		},
		decrement(state) {
			if (state.counter.value > 0) {
				state.counter.value -= 1;
			}
		},

		// Calls
		startCall(state, phone) {
			state.calls.currentCall = {
				phone: phone.payload,
				time: 0,
				start_date: new Date().toString()
			};
		},
		updateCurrentCallTime(state, time) {
			state.calls.currentCall.time = time.payload;
		},
		endCall(state) {
			state.calls.currentCall.end_date = new Date().toString();
			state.calls.calls.push({...state.calls.currentCall});
			state.calls.currentCall = {};
		}
	}
});

export const { increment, decrement } = slice.actions
export const { startCall, updateCurrentCallTime, endCall } = slice.actions

export default slice.reducer
