import { useDispatch, useSelector } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { endCall, startCall, updateCurrentCallTime } from "../store/slice";

export const useLastCalls = () => {
	const calls = useSelector(state => state.calls.calls);

	return calls.map(call => ({
		...call,
		start_date: new Date(call.start_date),
		end_date: (call.end_date ? new Date(call.end_date) : undefined),
	}))
};

export const useCurrentCall = () => {
	const currentCall = useSelector(state => state.calls.currentCall);

	const updatedCurrentCall = {...currentCall};
	updatedCurrentCall.start_date = new Date(updatedCurrentCall.start_date);
	if (updatedCurrentCall.end_date) {
		updatedCurrentCall.end_date = new Date(updatedCurrentCall.end_date);
	}

	return updatedCurrentCall;
};

export const useCallsWebSocket = () => {
	const [callTimerInterval, setCallTimerInterval] = useState(0);
	const [callTimer, setCallTimer] = useState(0);
	const [lastJsonMessage, setLastJsonMessage] = useState({});
	const [connected, setConnected] = useState(false);
	const [_ws, setWs] = useState(null);

	useEffect(() => {
		const wsUrl = 'ws://crm.react.ws.nicolaschoquet.fr/ws'
		// const wsUrl = `ws://${window.location.hostname}:8080/ws`;
		const ws = new WebSocket(wsUrl);

		ws.addEventListener('open', () => {
			setConnected(true);
		});
		ws.addEventListener('error', () => {
			setConnected(false);
		});
		ws.addEventListener('close', () => {
			setConnected(false);
			setWs(null);
		});
		ws.addEventListener('message', ({ data }) => {
			setLastJsonMessage(JSON.parse(data));
		});

		setWs(ws);

		/*setTimeout(() => {
			ws.close();
		}, 10000);*/

		return () => {
			ws.close();
		}
	}, []);

	const sendJsonMessage = useCallback(message => {
		_ws?.send(JSON.stringify(message));
	}, [_ws]);

	const dispatch = useDispatch();

	useEffect(() => {
		const actions = {
			/**
			 * client receive call from mobile app
			 * @param {{ phone: string }} data
			 */
			send_call(data) {
				if (!callTimerInterval) {
					console.log('début appel', data.phone);

					dispatch(startCall(data.phone));

					setCallTimerInterval(setInterval(() => {
						setCallTimer(prev => prev + 1);
					}, 1000));
				}
			},
			/**
			 * client receive hang up from mobile app
			 * @param {{ phone: string }} data
			 */
			send_hang_up(data) {
				if (callTimerInterval) {
					console.log('fin appel', data.phone);
					dispatch(endCall());
					clearInterval(callTimerInterval);
					setCallTimerInterval(0);
					setCallTimer(0);
				}
			}
		};

		if (lastJsonMessage.channel in actions) {
			actions[lastJsonMessage.channel](lastJsonMessage.data);
		}
	}, [lastJsonMessage, callTimerInterval, dispatch]);
	useEffect(() => {
		dispatch(updateCurrentCallTime(callTimer));
	}, [callTimer, dispatch]);

	const connectionStatus = {
		[true]: 'Open',
		[false]: 'Closed',
	}[connected];

	return {
		connectionStatus,
		send_hang_up() {
			sendJsonMessage({
				channel: 'send_hang_up',
				data: {
					phone: lastJsonMessage.data.phone
				}
			})
		}
	};
};
